.image {
    max-width: 60%;
    margin: 1rem;
}

.entryContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}

.header {
    border-bottom: 1px solid #6a6a6a;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 1rem;
    width: 95%;

    display: flex;
    align-items: center;
}

.headerDate {
    font-size: 1.6rem;
    color: #3b3939;
    flex-grow: 1;
    flex-shrink: 1;
}

.headerAdmin {
    flex-grow: 0;
    flex-shrink: 1;
}

@media only screen and (max-width: 768px) {
    .categoryHeader {
        font-size: 2rem;
    }
    .image {
        max-width: 90%;
    }
}