body{
  font-family: 'PT Sans', sans-serif;
  margin: 0;
  background-color: #F7F7F7;
}

html {
  height: 100%;
}

* {
  font-size: large;
}

.title{
  font-size: 1.8rem;
  text-align: center;
}


.showOnSmallScreen {
}

.showOnBigScreen {
  display: none;
}

@media only screen and (min-width: 768px) {
  .title{
    font-size: 3rem;
    text-align: center;
  }
}

